import request from './index.js'

//获取中继列表
export function getTrunk(data = {}) {
    return request({
        url: "/api/trunk/search",
        method: "GET",
        params: data
    })
}

//获取中继简化列表
export function simpleTrunk(data = {}) {
    return request({
        url: "/api/trunk/simple",
        method: "GET",
        params: data
    })
}

//获取中继组简化列表
export function simpleGroup(data = {}) {
    return request({
        url: "/api/trunk/group/simple",
        method: "GET",
        params: data
    })
}


//获取中继/中继组简化列表
export function trunkTarget(data = {}) {
    return request({
        url: "/api/trunk/target",
        method: "GET",
        params: data
    })
}

//中继添加
export function addTrunk(data = {}) {
    return request({
        url: "/api/trunk/add",
        method: "POST",
        data
    })
}

//中继导入
export function impTrunk(data = {}) {
    return request({
        url: "/api/trunk/import",
        method: "POST",
        headers: {
            "Content-Type": "multipart/form-data;charset=UTF-8"
        },
        data
    })
}

//获取单个中继列表
export function findTrunk(data = {}) {
    return request({
        url: "/api/trunk/find",
        method: "GET",
        params: data
    })
}

//中继编辑
export function updateTrunk(data = {}) {
    return request({
        url: "/api/trunk/update",
        method: "PUT",
        headers: {
            "Content-Type": "application/json;chart-set:UTF-8"
        },
        data
    })
}

//中继删除
export function deleteTrunk(data = {}) {
    return request({
        url: "/api/trunk/delete",
        method: "DELETE",
        data
    })
}

//中继编码编辑
export function setCodeTrunk(data = {}) {
    return request({
        url: "/api/trunk/set/code",
        method: "PUT",
        headers: {
            "Content-Type": "application/json;chart-set:UTF-8"
        },
        data
    })
}

//中继号码转换设置
export function setTransformTrunk(data = {}) {
    return request({
        url: "/api/trunk/set/transform",
        method: "PUT",
        headers: {
            "Content-Type": "application/json;chart-set:UTF-8"
        },
        data
    })
}

//中继高级设置
export function setAdvancedTrunk(data = {}) {
    return request({
        url: "/api/trunk/set/advanced",
        method: "PUT",
        headers: {
            "Content-Type": "application/json;chart-set:UTF-8"
        },
        data
    })
}

//设置dod
export function setTrunkDod(data = {}) {
    return request({
        url: "/api/trunk/set/dod",
        method: "PUT",
        headers: {
            "Content-Type": "application/json;chart-set:UTF-8"
        },
        data
    })
}

//中继批量删除
export function batchdelTrunk(data = {}) {
    return request({
        url: "/api/trunk/batchdel",
        method: "DELETE",
        data
    })
}

//获取中继dod列表
export function getDodData(data = {}) {
    return request({
        url: "/api/trunk/dod/search",
        method: "GET",
        params: data
    })
}

//获取中继dod信息
export function getDodInfo(data = {}) {
    return request({
        url: "/api/trunk/dod/find",
        method: "GET",
        params: data
    })
}

//新增dod
export function addDod(data = {}) {
    return request({
        url: "/api/trunk/dod/add",
        method: "POST",
        params: data
    })
}

//导入dod
export function impDod(data = {}) {
    return request({
        url: "/api/trunk/dod/import",
        method: "POST",
        headers: {
            "Content-Type": "multipart/form-data;charset=UTF-8"
        },
        data
    })
}


//编辑dod
export function updateDodInfo(data = {}) {
    return request({
        url: "/api/trunk/dod/update",
        method: "PUT",
        params: data
    })
}

//删除dod
export function delDod(data = {}) {
    return request({
        url: "/api/trunk/dod/delete",
        method: "DELETE",
        params: data
    })
}

//批量删除dod
export function bDelDod(data = {}) {
    return request({
        url: "/api/trunk/dod/batchdel",
        method: "DELETE",
        params: data
    })
}
