<template>
  <el-card class="!border-none mb10 status-tag" v-show="sysTitle" shadow="never">
    <div style="display: flex">
      <el-alert
          type="warning"
          :title="sysTitle"
          :closable="false"
          show-icon
          style="flex-direction: row"
      />
      <a href="/#/setUp/systemSetup"
         style="width:60px;height:40px;line-height: 40px;margin-left: 5px;color:rgb(59 82 224)">{{ $t('更新') }}</a>
    </div>
  </el-card>
  <div>
    <el-card shadow="hover">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
          <div class="grid-content bg-purple">
            <div class="y-flex">
              <h3>{{ $t('部门设置') }}</h3>
              <el-button size="small" type="primary" @click="onOpenAddMenu">{{ $t('添加') }}</el-button>
            </div>
            <div class="v-flex">
              <DepartmentMenu @eventIsNum="receiveChildNum" ref="RefCid"/>
            </div>
          </div>
        </el-col>
        <el-divider direction="vertical" class="conceal"></el-divider>
        <el-col :xs="24" :sm="24" :md="24" :lg="17" :xl="17">
          <div class="grid-content bg-purple-light">
            <div class="n-flex">
              <span>{{ $t('部门') }}：{{ department.name }}</span>
              <div class="ToDelete">
                <el-button type="text" @click="onOpenEditMenu">
                  {{ $t('部门编辑') }}
                </el-button>
                <el-button type="text" @click="onRowDel" class="u-delete" style="margin-left: 0px !important;">
                  {{ $t('部门删除') }}
                </el-button>
              </div>
            </div>
            <p>{{ $t('部门成员') }}</p>
            <Member ref="bmcy"/>
          </div>
        </el-col>
      </el-row>
    </el-card>
    <AddMenu ref="addMenuRef" @print="print"/>
    <EditMenu ref="editMenuRef" @print="print" @prink="prink"/>
  </div>
</template>
<script>
import {
  ref,
  reactive,
  toRefs,
  onMounted
} from 'vue';
import {useI18n} from "vue-i18n";
import DepartmentMenu from './departmentCom/DepartmentMenu';
import Member from './departmentCom/member';
import AddMenu from './departmentCom/addMenu';
import EditMenu from './departmentCom/editMenu';
import {
  getDepartment, deleteDepartment
} from '@/http/group.api.js';
import {
  ElMessageBox,
  ElNotification
} from 'element-plus';
import router from "@/router";

function blackList(state, bmcy) {
  getDepartment().then(res => {
    state.department  = res.data[0];
    state.trunkNumber = res.data[0].departmentTrunk !== undefined ? res.data[0].departmentTrunk.number : '';
    state.sysTitle    = res.tag;
    if (res.tag) {
      router.push('/setUp/systemSetup')
    }
    bmcy.value.memberList(state.department);
  }).catch(err => {
    console.log(err, 'err');
  })
}

export default {
  name: 'Department',
  components: {
    DepartmentMenu,
    Member,
    AddMenu,
    EditMenu
  },
  setup() {
    const {t}   = useI18n()
    const state = reactive({
      sysTitle: "",
      department: {},
      trunkNumber: '',
    })
    onMounted(() => {
      blackList(state, bmcy);
    })
    const addMenuRef = ref();
    const RefCid     = ref();
    const print      = () => {
      RefCid.value.print();
    }
    const bmcy       = ref();

    function receiveChildNum(e) {
      state.department  = e;
      state.trunkNumber = e.trunkNumber;
      bmcy.value.memberList(state.department);
    }

    const prink          = () => {
      RefCid.value.memberData(state.department.id);
    }
    const editMenuRef    = ref();
    // 打开新增菜单弹窗
    const onOpenAddMenu  = () => {
      addMenuRef.value.openDialog();
    };
    // 打开编辑菜单弹窗
    const onOpenEditMenu = (row) => {
      row.id = state.department.id;
      editMenuRef.value.openDialog(row);
    };
    //部门删除
    const onRowDel       = () => {
      ElMessageBox.confirm(t("你确定要删除该部门吗"), t("提示"), {
        confirmButtonText: t("确定"),
        cancelButtonText: t("取消"),
        type: "warning",
        callback: (action) => {
          if (action === "confirm") {
            let params = {
              id: state.department.id
            }
            deleteDepartment(params).then(res => {
              if (res.code == 200) {
                ElNotification({
                  title: t("成功"),
                  message: t("删除成功"),
                  type: "success",
                  duration: 2000,
                });
                print()
              } else {
                ElNotification({
                  title: t('失败'),
                  message: res.msg,
                  type: 'error',
                  duration: 2000,
                });
              }
            })
          }
        }
      })
    }
    return {
      onOpenAddMenu,
      addMenuRef,
      RefCid,
      onOpenEditMenu,
      editMenuRef,
      ...toRefs(state),
      receiveChildNum,
      print,
      onRowDel,
      bmcy,
      prink,
    }
  }
}
</script>
<style scoped>
.el-divider--vertical {
  width: 2px;
  height: auto;
}

.n-flex, .y-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.y-flex h3 {
  font-weight: 500;
  font-size: 1.2rem;
}

.n-flex span,
.el-link--success {
  font-size: 1rem !important;
}

.grid-content p {
  font-weight: 500;
  margin-top: 15px;
  font-size: 1rem;
  color: rgb(125, 125, 125);
}

.v-flex {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
}

.el-link--success {
  color: #409eff !important;
  font-weight: 500 !important;
}

.el-link--success:hover {
  color: rgb(121, 187, 255) !important;
}

@media only screen and (max-width: 1200px) {
  .conceal {
    display: none;
  }

  .v-flex {
    padding-bottom: 10px;
    border-bottom: 2px solid #2222;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 550px) {
  .n-flex {
    flex-direction: column;
  }

  .n-flex span {
    padding-bottom: 10px;
  }
}
</style>
