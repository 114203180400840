import request from './index.js'

export function getTrunkGroup(data={}) {
  return request({
    url: "/api/trunk/group/search",
    method: "GET",
    params: data
  })
}

export function delTrunkGroup(data={}) {
  return request({
    url: "/api/trunk/group/delete",
    method: "DELETE",
    data
  })
}

export function delTrunkGroupMem(data={}) {
  return request({
    url: "/api/trunk/group/member",
    method: "DELETE",
    data
  })
}

export function findTrunkGroup(data={}) {
  return request({
    url: "/api/trunk/group/find",
    method: "GET",
    params: data
  })
}

export function addTrunkGroup(data={}) {
  return request({
    url: "/api/trunk/group/add",
    method: "POST",
    data
  })
}

export function putTrunkGroup(data={}) {
  return request({
    url: "/api/trunk/group/update",
    method: "PUT",
    headers: {
      "Content-Type": "application/json;chart-set:UTF-8"
    },
    data
  })
}

//获取分组列表
export function getGroup(data={}) {
  return request({
    url: "/api/group/search",
    method: "GET",
    params: data
  })
}

//获取队列列表
export function getQueue(data={}) {
  return request({
    url: "/api/queue/search",
    method: "GET",
    params: data
  })
}

//获取部门列表
export function getDepartment(data={}) {
  return request({
    url: "/api/department/search",
    method: "GET",
    params: data
  })
}

//获取部门简化列表
export function simpleDepartment(data={}) {
  return request({
    url: "/api/department/simple",
    method: "GET",
    params: data
  })
}

//获取单个分组列表
export function findGroup(data={}) {
  return request({
    url: "/api/group/find",
    method: "GET",
    params: data
  })
}

//获取单个队列列表
export function findQueue(data={}) {
  return request({
    url: "/api/queue/find",
    method: "GET",
    params: data
  })
}

//单个部门查询
export function findDepartment(data={}) {
  return request({
    url: "/api/department/find",
    method: "GET",
    params: data
  })
}

//单个部门成员查询
export function memberDepartment(data={}) {
  return request({
    url: "/api/department/member",
    method: "GET",
    params: data
  })
}

//分组删除
export function deleteGroup(data={}) {
  return request({
    url: "/api/group/delete",
    method: "DELETE",
    data
  })
}

//队列删除
export function deleteQueue(data={}) {
  return request({
    url: "/api/queue/delete",
    method: "DELETE",
    data
  })
}

//部门删除
export function deleteDepartment(data={}) {
  return request({
    url: "/api/department/delete",
    method: "DELETE",
    data
  })
}

//部门成员删除
export function deleteMember(data={}) {
  return request({
    url: "/api/department/member",
    method: "DELETE",
    data
  })
}

//分组添加
export function addGroup(data={}) {
  return request({
    url: "/api/group/add",
    method: "POST",
    data
  })
}

//队列添加
export function addQueue(data={}) {
  return request({
    url: "/api/queue/add",
    method: "POST",
    data
  })
}

//部门添加
export function addDepartment(data={}) {
  return request({
    url: "/api/department/add",
    method: "POST",
    data
  })
}

//部门绑定成员
export function departmentMember(data={}) {
  return request({
    url: "/api/department/member",
    method: "POST",
    data
  })
}

//部门解绑成员
export function deleteDepartmentMember(data={}) {
  return request({
    url: "/api/department/member",
    method: "DELETE",
    data
  })
}

//分组绑定成员
export function groupMember(data={}) {
  return request({
    url: "/api/group/member",
    method: "POST",
    data
  })
}

//分组解绑成员
export function deleteGroupMember(data={}) {
  return request({
    url: "/api/group/member",
    method: "DELETE",
    data
  })
}

//队列绑定成员
export function queueMember(data={}) {
  return request({
    url: "/api/queue/member",
    method: "POST",
    data
  })
}

//队列解绑成员
export function deleteQueueMember(data={}) {
  return request({
    url: "/api/queue/member",
    method: "DELETE",
    data
  })
}

//分组编辑
export function updateGroup(data={}) {
  return request({
    url: "/api/group/update",
    method: "PUT",
    headers: {
      "Content-Type": "application/json;chart-set:UTF-8"
    },
    data
  })
}

//队列编辑
export function updateQueue(data={}) {
  return request({
    url: "/api/queue/update",
    method: "PUT",
    headers: {
      "Content-Type": "application/json;chart-set:UTF-8"
    },
    data
  })
}

//部门编辑
export function updateDepartment(data={}) {
  return request({
    url: "/api/department/update",
    method: "PUT",
    headers: {
      "Content-Type": "application/json;chart-set:UTF-8"
    },
    data
  })
}
