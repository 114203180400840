<template>
  <div>
    <el-input size="small" v-model="filterText" :placeholder="$t('查找部门')" prefix-icon="el-icon-search"/>
    <el-scrollbar max-height="450px">
      <el-tree :data="data" node-key="id" :props="defaultProps" :expand-on-click-node="false"
               :filter-node-method="filterNode" ref="tree" @node-click="handleBucketClick">
        <template #default="{ node }">
          <span class="custom-tree-node">
            <span>{{ node.label }}</span>
          </span>
        </template>
      </el-tree>
    </el-scrollbar>
  </div>
</template>
<script>
import {reactive, toRefs, onMounted} from 'vue';
import {
  getDepartment, findDepartment
} from '@/http/group.api.js';

function blackList(state) {
  getDepartment().then(res => {
    state.data = res.data;
  }).catch(err => {
    console.log(err, 'err');
  })
}

export default {
  name: 'DepartmentMenu',
  setup(props, context) {
    const state = reactive({
      id: '',
      filterText: '',
      data: [],
      defaultProps: {
        children: 'children',
        label: 'name',
      },
      department: {
        id: '',
        name: '',
        sysnumber_id: '',
      },
    });
    const print = () => {
      blackList(state);
    }
    onMounted(() => {
      blackList(state);
    })
    const handleBucketClick = (v) => {
      state.id   = v.id;
      let params = {
        id: state.id
      }
      findDepartment(params).then(res => {
        state.department = res.data;
        context.emit('eventIsNum', state.department);
      })
    };
    const memberData        = (id) => {
      let params = {
        id: id
      }
      findDepartment(params).then(res => {
        state.department = res.data;
        context.emit('eventIsNum', state.department);
      })
    }
    return {
      ...toRefs(state),
      handleBucketClick,
      print,
      memberData,
    }
  },
  data() {
    return {}
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    },
  },

  methods: {
    filterNode(value, data) {
      if (!value) return true
      return data.name.indexOf(value) !== -1
    },
  },
}
</script>
<style scoped>
.el-tree {
  margin-top: 15px;
}

.el-tree :deep(.el-tree-node__content) {
  height: 40px !important;
}

.el-tree :deep(.el-tree-node__expand-icon) {
  font-size: 15px !important;
}

.custom-tree-node {
  font-size: 17px;
}
</style>
