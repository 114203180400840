<template>
  <div class="system-menu-container">
    <el-dialog :title="$t('新增部门')" v-model="isShowDialog" @close="initForm" width="60%">
      <el-divider></el-divider>
      <el-form v-if="isShowDialog" :model="ruleForm" size="small" label-width="90px" label-position="top"
               ref="validateFrom" :rules="rules">
        <el-row :gutter="35">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item :label="$t('名称')" prop="name">
              <el-input v-model="ruleForm.name" :placeholder="$t('名称')" maxlength="16" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item :label="$t('归属')">
              <el-cascader v-model="ruleForm.parentId" :options="data" :props="defaultProps" :show-all-levels="false"
                           clearable filterable :placeholder="$t('默认为一级部门')"/>
            </el-form-item>
          </el-col>
<!--          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">-->
<!--            <el-form-item :label="$t('中继')">-->
<!--              <el-select v-model="ruleForm.trunk_id" filterable clearable :placeholder="$t('请选择中继')">-->
<!--                <el-option v-for="item in trunk" :key="item.id" :label="item.name" :value="item.id"></el-option>-->
<!--              </el-select>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">-->
<!--            <el-form-item :label="$t('出局外显')">-->
<!--              <el-input v-model="ruleForm.trunk_number" :placeholder="$t('出局外显')" :disabled="ruleForm.trunk_id == ''" maxlength="32" onkeyup="value=value.replace(/[^\d]/g,'')" clearable></el-input>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item :label="$t('呼叫权限')" prop="call_authority">
              <el-select v-model="ruleForm.call_authority" :placeholder="$t('请选择呼叫权限')">
                <el-option :label="$t('禁止外呼')" value="0"></el-option>
                <el-option :label="$t('局内')" value="1"></el-option>
                <el-option :label="$t('本地')" value="2"></el-option>
                <el-option :label="$t('国内')" value="4"></el-option>
                <el-option :label="$t('国际')" value="8"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
				<span class="dialog-footer">
					<el-button @click="onCancel" size="small">{{ $t('取消') }}</el-button>
					<el-button type="primary" @click="onSubmit" size="small">{{ $t('保存') }}</el-button>
				</span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  reactive,
  toRefs,
  ref, computed
} from 'vue';
import {useI18n} from "vue-i18n";
import {
  simpleDepartment,
  addDepartment
} from '@/http/group.api.js';
import {
  simpleTrunk
} from '@/http/trunk.api.js';
import {
  ElNotification
} from 'element-plus';

function blackList(state) {
  simpleDepartment().then(res => {
    state.data = res.data;
  }).catch(err => {
    console.log(err, 'err');
  })
  simpleTrunk().then(res => {
    state.trunk = res.data;
  }).catch(err => {
    console.log(err, 'err');
  })
}

export default {
  name: 'systemAddMenu',
  emits: ["print"],
  setup(props, context) {
    const {t}          = useI18n()
    const state        = reactive({
      isShowDialog: false,
      ruleForm: {
        name: '',
        parentId: '',
        sysnumber_id: '',
        call_authority: '',
        trunk_id: '',
        trunk_number: '',
      },
      data: [],
      trunk: [],
      defaultProps: {
        children: 'children',
        label: 'name',
        value: "id",
        checkStrictly: true,
      },
      activeName: 'first',
      tabPosition: 'left',
    });
    const validateFrom = ref()
    //校验规则
    const rules        = computed(() => {
      const rule = {
        name: [{
          required: true,
          message: t("请输入名称"),
          trigger: "blur"
        }],
        call_authority: [{
          required: true,
          message: t("请选择呼叫权限"),
          trigger: "change"
        }],
      };
      return rule;
    })
    // 打开弹窗
    const openDialog   = () => {
      state.isShowDialog = true;
      blackList(state);
    };
    // 关闭弹窗
    const closeDialog  = () => {
      state.isShowDialog = false;
    };
    // 取消
    const onCancel     = () => {
      closeDialog();
      initForm();
    };
    // 新增
    const onSubmit     = () => {
      validateFrom.value.validate((valid) => {
        if (valid) {
          const params = {
            name: state.ruleForm.name,
            parent_id: state.ruleForm.parentId[state.ruleForm.parentId.length - 1],
            trunk_id: state.ruleForm.trunk_id,
            call_authority: state.ruleForm.call_authority,
            trunk_number: state.ruleForm.trunk_number,
          };
          addDepartment(params).then((res) => {
            if (res.code == 200) {
              ElNotification({
                title: t("成功"),
                message: t("添加成功"),
                type: 'success',
                duration: 2000,
              });
              validateFrom.value.resetFields();
              state.isShowDialog = false;
              context.emit("print");
            } else {
              ElNotification({
                title: t('失败'),
                message: res.msg,
                type: 'error',
                duration: 2000,
              });
            }
          })
        }
      });
    };
    // 表单初始化，方法：`resetFields()` 无法使用
    const initForm     = () => {
      state.ruleForm.name           = '';
      state.ruleForm.parentId       = '';
      state.ruleForm.sysnumber_id   = '';
      state.ruleForm.call_authority = '';
      state.ruleForm.trunk_id       = '';
      state.ruleForm.trunk_number   = '';
    };
    return {
      openDialog,
      closeDialog,
      onCancel,
      onSubmit,
      initForm,
      ...toRefs(state),
      validateFrom,
      rules,
    };
  },
};
</script>
<style lang="scss" scoped>
.el-dialog__body {
  padding-top: 0;
}

.el-divider--horizontal {
  margin-top: 15px;
}

.el-button--primary.is-disabled {
  display: block;
}

.el-button [class*=el-icon-] + span {
  margin-left: 0;
}

.el-transfer__buttons {
  padding: 0 10px;
}

.member {
  font-size: 1rem;
  margin-left: 25px;
  margin-bottom: 5px;
}

.el-transfer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.el-transfer {
  --el-transfer-panel-width: 40%;
}
</style>
