<template>
  <div>
    <div @click="memberList"></div>
    <el-table :data="tableData" stripe style="width: 100%" class="table" id="out-table">
      <el-table-column prop="dn" :label="$t('分机')" show-overflow-tooltip></el-table-column>
      <el-table-column prop="display_name" :label="$t('名称')" show-overflow-tooltip></el-table-column>
      <el-table-column prop="duty" :label="$t('职务')" show-overflow-tooltip></el-table-column>
      <el-table-column prop="call_authority" :label="$t('权限')" show-overflow-tooltip>
        <template #default="scope">
          <el-tag size="medium" v-if="scope.row.call_authority == 0">{{ $t('禁止') }}</el-tag>
          <el-tag size="medium" v-if="scope.row.call_authority == 1">{{ $t('局内') }}</el-tag>
          <el-tag size="medium" v-if="scope.row.call_authority == 2">{{ $t('本地') }}</el-tag>
          <el-tag size="medium" v-if="scope.row.call_authority == 4">{{ $t('国内') }}</el-tag>
          <el-tag size="medium" v-if="scope.row.call_authority == 8">{{ $t('国际权限') }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="is_record" :label="$t('录音')" show-overflow-tooltip>
        <template #default="scope">
          <el-tag size="medium" type="success" v-if="scope.row.is_record == 1">{{ $t('启用') }}</el-tag>
          <el-tag size="medium" type="info" v-if="scope.row.is_record == 0">{{ $t('禁用') }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="path" :label="$t('操作')" width="90" fixed="right">
        <template #default="scope">
          <el-button size="mini" type="text" @click="onRowDel(scope.row)" class="u-delete">{{ $t('删除') }}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination v-if="clientWidth >= 1000" @size-change="onHandleSizeChange" @current-change="onHandleCurrentChange"
                   class="mt15" :pager-count="5" :page-count="pageCount" :current-page="pageNum" background
                   :page-size="pageSize"
                   layout="total, prev, pager, next, jumper" :total="total">
    </el-pagination>
    <el-pagination v-else @size-change="onHandleSizeChange" @current-change="onHandleCurrentChange"
                   :page-size="pageSize" small layout=" prev, pager, next" :page-count="pageCount"
                   :current-page="pageNum"
                   :total="total" class="mt15"></el-pagination>
  </div>
</template>
<script>
import {
  reactive,
  toRefs,
} from 'vue'
import {useI18n} from "vue-i18n";
import {
  ElMessageBox,
  ElNotification
} from 'element-plus';
import {
  memberDepartment,
  deleteMember
} from '@/http/group.api.js';

export default {
  name: 'member',
  setup() {
    const {t}   = useI18n()
    const state = reactive({
      tableData: [],
      pageSize: 5, //每页数据条数
      pageNum: 1, //页码
      total: 0, //总数据条数
      pageCount: 0, //总页数
      department: [],
      clientWidth: window.innerWidth,
    });

    function memberList(e) {
      state.department = e;
      memberLists();
    }

    function memberLists() {
      let params = {
        id: state.department.id,
        page: state.pageNum //页码
      }
      memberDepartment(params).then(res => {
        state.tableData = res.data.list;
        state.pageCount = res.data.pages;
        state.pageSize  = res.data.length;
        state.total     = res.data.rows;
      })
    }

    // 当前行删除
    const onRowDel              = (row) => {
      const member = Array();
      member.push(row.id);
      ElMessageBox.confirm(t("你确定要移除该分机吗"), t("提示"), {
        confirmButtonText: t("确定"),
        cancelButtonText: t("取消"),
        type: "warning",
        callback: (action) => {
          if (action === "confirm") {
            let params = {
              id: state.department.id,
              members: member
            }
            deleteMember(params).then((res) => {
              if (res.code == 200) {
                ElNotification({
                  title: t("成功"),
                  message: t("删除成功"),
                  type: "success",
                  duration: 2000,
                });
                memberLists();
              } else {
                ElNotification({
                  title: t('失败'),
                  message: res.msg,
                  type: 'error',
                  duration: 2000,
                });
              }
            })

          }
        },
      });
    };
    // 分页改变
    const onHandleSizeChange    = (val) => {
      state.pageSize = val;
      memberLists()
    };
    // 分页改变
    const onHandleCurrentChange = (val) => {
      state.pageNum = val;
      memberLists()
    };
    return {
      ...toRefs(state),
      onRowDel,
      onHandleSizeChange,
      onHandleCurrentChange,
      memberList,
      memberLists,
    }
  }
}
</script>
<style lang="scss" scoped>
.table {
  :deep(.hidden-columns) {
    visibility: visible !important;
    position: relative !important;
    z-index: 1 !important;
  }
}

.mt15 {
  text-align: center;
  margin-top: 15px;
}
</style>
