<template>
  <div class="system-menu-container">
    <el-dialog :title="$t('编辑部门')" v-model="isShowDialog" width="60%">
      <el-divider></el-divider>
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane :label="$t('基本信息')" name="first">
          <el-form v-if="isShowDialog" :model="ruleForm" size="small" label-width="90px" label-position="top"
                   ref="validateFrom"
                   :rules="rules">
            <el-row :gutter="35">
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item :label="$t('名称')" prop="name">
                  <el-input v-model="ruleForm.name" :placeholder="$t('名称')" maxlength="16" clearable></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item :label="$t('归属')">
                  <el-cascader v-model="ruleForm.parent_id" :options="datae" :props="defaultProps"
                               :show-all-levels="false" clearable filterable
                               :placeholder="$t('默认为一级部门')"></el-cascader>
                </el-form-item>
              </el-col>
              <!--              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">-->
              <!--                <el-form-item :label="$t('中继')">-->
              <!--                  <el-select v-model="ruleForm.trunk_id" filterable clearable :placeholder="$t('请选择中继')">-->
              <!--                    <el-option v-for="item in trunk" :key="item.id" :label="item.name" :value="item.id"></el-option>-->
              <!--                  </el-select>-->
              <!--                </el-form-item>-->
              <!--              </el-col>-->
              <!--              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">-->
              <!--                <el-form-item :label="$t('出局外显')">-->
              <!--                  <el-input v-model="ruleForm.trunk_number" :placeholder="$t('出局外显')" :disabled="ruleForm.trunk_id == ''" maxlength="32" onkeyup="value=value.replace(/[^\d]/g,'')" clearable></el-input>-->
              <!--                </el-form-item>-->
              <!--              </el-col>-->
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item :label="$t('呼叫权限')" prop="call_authority">
                  <el-select v-model="ruleForm.call_authority" :placeholder="$t('请选择呼叫权限')">
                    <el-option :label="$t('禁止外呼')" :value="0"></el-option>
                    <el-option :label="$t('局内')" :value="1"></el-option>
                    <el-option :label="$t('本地')" :value="2"></el-option>
                    <el-option :label="$t('国内')" :value="4"></el-option>
                    <el-option :label="$t('国际')" :value="8"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <span class="dialog-footer mt15" style="float:right">
              <el-button @click="onCancel" size="small">{{ $t('取消') }}</el-button>
              <el-button type="primary" @click="onSubmit" size="small">{{ $t('保存') }}</el-button>
            </span>
          </el-form>
        </el-tab-pane>
        <el-tab-pane :label="$t('部门成员')" name="second">
          <el-form :model="ruleForm" size="small" label-width="90px" label-position="top">
            <el-row :gutter="35">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <p class="member">{{ $t('分机成员') }}</p>
                <el-transfer v-model="rightValue" :props="{ key: 'id', label: 'dn',}" :data="leftValue" filterable
                             :titles="[$t('可用分机'),$t('成员分机')]" :button-texts="[$t('移除'), $t('添加')]"
                             :filter-placeholder="$t('搜索分机')" @change="getObject">
                </el-transfer>
              </el-col>
            </el-row>
          </el-form>
        </el-tab-pane>
      </el-tabs>
      <!--      <template #footer>-->
      <!--				<span class="dialog-footer">-->
      <!--					<el-button @click="onCancel" size="small">{{ $t('取消') }}</el-button>-->
      <!--					<el-button type="primary" @click="onSubmit" size="small">{{ $t('保存') }}</el-button>-->
      <!--				</span>-->
      <!--      </template>-->
    </el-dialog>
  </div>
</template>

<script>
import {
  reactive,
  toRefs,
  ref, computed
} from 'vue';
import {useI18n} from "vue-i18n";
import {
  updateDepartment,
  findDepartment,
  simpleDepartment,
  departmentMember,
  deleteDepartmentMember
} from '@/http/group.api.js';
import {
  avaliableStation
} from '@/http/extension.api.js';
import {
  simpleTrunk
} from '@/http/trunk.api.js';
import {
  ElNotification
} from 'element-plus';

function departmentEditData(state) {
  let params = {
    id: state.id
  }
  findDepartment(params).then(res => {
    if (res.code == 200) {
      state.ruleForm   = res.data;
      // state.leftValue = res.data.stations;
      state.rightValue = res.data.station_ids;
      state.parent_id  = res.data.parent_id;
    } else {
      ElNotification({
        title: '失败',
        message: res.msg,
        type: 'error',
        duration: 2000,
      });
    }
  })
  simpleTrunk().then(res => {
    state.trunk = res.data;
  }).catch(err => {
    console.log(err, 'err');
  })
}

export default {
  name: 'systemEditMenu',
  emits: ["print", "prink"],
  setup(props, context) {
    const {t}          = useI18n();
    const state        = reactive({
      isShowDialog: false,
      id: '',
      parent_id: '',
      ruleForm: {
        name: '',
        parent_id: '',
        call_authority: '',
        trunk_id: '',
        trunk_number: '',
      },
      activeName: 'first',
      tabPosition: 'left',
      datae: [],
      trunk: [],
      defaultProps: {
        children: 'children',
        label: 'name',
        value: "id",
        checkStrictly: true,
      },
      rightValue: [],
      leftValue: [],
      editLayoutData: [],
    });
    const validateFrom = ref()
    //校验规则
    const rules        = computed(() => {
      const rule = {
        name: [{
          required: true,
          message: t("请输入名称"),
          trigger: "blur"
        }],
        call_authority: [{
          required: true,
          message: t("请选择呼叫权限"),
          trigger: "change"
        }],
      };
      return rule;
    })
    // 打开弹窗
    const openDialog   = (row) => {
      state.id           = row.id;
      state.isShowDialog = true;
      state.activeName = 'first',
          departmentEditData(state);
      let simpleParam = {
        id: state.id
      }
      simpleDepartment(simpleParam).then(res => {
        state.datae = res.data;
      })
      let params = {
        keyword: 'department',
        id: state.id
      }
      avaliableStation(params).then(res => {
        if (res.code == 200) {
          state.leftValue = res.data;
        } else {
          ElNotification({
            title: t('失败'),
            message: res.msg,
            type: 'error',
            duration: 2000,
          });
        }
      })
    };
    // 关闭弹窗
    const closeDialog  = () => {
      state.isShowDialog = false;
    };
    // 取消
    const onCancel     = () => {
      closeDialog();
      initForm();
    };
    // 编辑
    const onSubmit     = () => {
      let estimate = null;
      if (state.ruleForm.parent_id == null) {
        estimate = "";
      } else if (state.ruleForm.parent_id == state.parent_id) {
        estimate = state.ruleForm.parent_id
      } else {
        estimate = state.ruleForm.parent_id[state.ruleForm.parent_id.length - 1]
      }
      validateFrom.value.validate((valid) => {
        if (valid) {
          let params = {
            id: state.id,
            name: state.ruleForm.name,
            trunk_id: state.ruleForm.trunk_id,
            call_authority: state.ruleForm.call_authority,
            parent_id: estimate,
            trunk_number: state.ruleForm.trunk_number,
          };
          updateDepartment(params).then(res => {
            if (res.code == 200) {
              ElNotification({
                title: t("成功"),
                message: t("编辑成功"),
                type: 'success',
                duration: 2000,
              });
              state.isShowDialog = false;
              context.emit("print");
            } else {
              ElNotification({
                title: t('失败'),
                message: res.msg,
                type: 'error',
                duration: 2000,
              });
            }
          })
        }
      });
    };
    // 右侧列表元素变化时触发
    // value右边框里面所有的key值  direction是方向(left和right)，movedKeys是移动的key值
    const getObject = (value, direction, movedKeys) => {
      // 判断移动方向
      if (direction === "right") {
        state.editLayoutData = movedKeys;
        let params           = {
          id: state.id,
          members: state.editLayoutData
        }
        departmentMember(params).then(res => {
          if (res.code == 200) {
            ElNotification({
              title: t("成功"),
              message: t("编辑成功"),
              type: "success",
              duration: 2000,
            });
            context.emit("prink");
          } else {
            ElNotification({
              title: t('失败'),
              message: res.msg,
              type: 'error',
              duration: 2000,
            });
          }
        })
      } else {
        state.editLayoutData = movedKeys;
        let params           = {
          id: state.id,
          members: state.editLayoutData
        }
        deleteDepartmentMember(params).then(res => {
          if (res.code == 200) {
            ElNotification({
              title: t("成功"),
              message: t("解绑成功"),
              type: "success",
              duration: 2000,
            });
            context.emit("prink");
          } else {
            ElNotification({
              title: t('失败'),
              message: res.msg,
              type: 'error',
              duration: 2000,
            });
          }
        })
      }
    }
    // 表单初始化，方法：`resetFields()` 无法使用
    const initForm  = () => {
      state.ruleForm.name         = '';
      state.ruleForm.department   = '';
      state.ruleForm.jurisdiction = '';
    };
    return {
      openDialog,
      closeDialog,
      onCancel,
      onSubmit,
      ...toRefs(state),
      getObject,
      validateFrom,
      rules
    };
  },
};
</script>
<style lang="scss">
.el-dialog__body {
  padding-top: 0;
}

.el-divider--horizontal {
  margin-top: 15px;
}

.el-button--primary.is-disabled {
  display: block;
}

.el-button [class*=el-icon-] + span {
  margin-left: 0;
}

.el-transfer__buttons {
  padding: 0 10px;
}

.member {
  font-size: 1rem;
  margin-left: 25px;
  margin-bottom: 5px;
}

.el-transfer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.el-transfer {
  --el-transfer-panel-width: 40%;
}
</style>
